import React, { lazy, Suspense, useEffect } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@iso/components/utility/loader';
import { fetchVideoUploadSize } from '@iso/lib/helpers/utility';
import ErrorBoundary from './ErrorBoundary';
import PUBLIC_ROUTE from './route.constants';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.ADMINISTRATOR,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword'),
    ),
  },
  {
    path: PUBLIC_ROUTE.CREATE_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/CreatePassword/CreatePassword'),
    ),
  },
  {
    path: PUBLIC_ROUTE.HANDYMAN_WIZARD,
    component: lazy(() =>
      import('@iso/containers/Pages/HandymanWizard/HandymanWizard'),
    ),
    userType: ['Handyman'],
  },
];

function PrivateRoute({ children, ...rest }) {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const uData = useSelector((state) => state.Auth.userData);
  // const verified =
  //   uData && uData.user_type === 'Handyman' ? uData.admin_verified : true;
  const redPath = uData && uData.redirect ? uData.redirect : 'signin';
  if (isLoggedIn) {
    return <Route {...rest}>{children}</Route>;
  }
  // if (isLoggedIn) {
  //   redPath = 'handyman-verification';
  // }

  return (
    <Redirect
      to={{
        pathname: `/${redPath}`,
        state: { from: location },
      }}
    />
  );
}
export default function Routes() {
  // const user = useSelector((state) => state.Auth.userData);
  // const userType = user && user.user_type ? user.user_type : '';
  // const verified =
  //   user && user.user_type === 'Handyman' ? user.admin_verified : true;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchVideoUploadSize(dispatch);
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route) => {
              // const findUserType = route.userType
              //   ? includes(route.userType, userType)
              //   : 'hack';
              // Restrict rendering of handyman wizard page if handyman already verified
              // if (
              //   !findUserType ||
              //   (verified && route.path === PUBLIC_ROUTE.HANDYMAN_WIZARD)
              // )
              //   return null;
              return (
                <Route key={route.path} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              );
            })}
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
            <Redirect to="/" />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
