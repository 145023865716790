/* eslint-disable no-nested-ternary */
const prod = process.env.NODE_ENV === 'production';

const devLive = process.env.REACT_APP_DEV_LIVE === 'live' || false;
const devStaging = process.env.REACT_APP_DEV_LIVE === 'staging' || false;

const serverUrl = devLive
  ? 'https://api.bookrejuuv.com/'
  : devStaging
  ? 'https://sapi.bookrejuuv.com/'
  : 'http://50.18.4.44:1337/';

const webSite = devLive
  ? 'https://bookrejuuv.com/'
  : devStaging
  ? 'https://staging.bookrejuuv.com/'
  : 'http://50.18.4.44:3000/';

const provider = devLive
  ? 'https://provider.bookrejuuv.com/'
  : devStaging
  ? 'https://sprovider.bookrejuuv.com/'
  : 'http://50.18.4.44:3001/';

export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: prod ? serverUrl : 'http://localhost:1337/',
  // siteUrl: !prod ? 'http://54.219.228.39:1337/' : 'http://192.168.0.117:3001/',
  siteUrl: prod ? webSite : 'http://localhost:3000/',
  providerUrl: prod ? provider : 'http://localhost:3001/',
  csvUrl: prod ? `${serverUrl}` : 'http://localhost:1337/',
  domainUrl: prod ? '' : 'http://localhost:3000',
  sailsUrl: prod ? serverUrl : 'http://192.168.2.171:1337/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'bi6pdq3ma8n5uoqjw73l6ximbi475212fvfbir2mnz40yfju',
  dashboard: '/dashboard',
};
