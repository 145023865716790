import siteConfig from '@iso/config/site.config';
import { omitBy, isNull, isUndefined, isEmpty } from 'lodash';
import { getToken, getLanguage, getCountry } from '@iso/lib/helpers/utility';
import axios from 'axios';
import Notification from '@iso/components/Notification';
import authAction from '@iso/redux/auth/actions';
import appActions from '@iso/redux/app/actions';
import { store } from '@iso/redux/store';

const defaultHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

async function apiCall(
  url,
  data = {},
  methodParm = 'GET',
  heads = defaultHeader,
  formData = false,
  fetchCommonData = false,
  timeout = 3000000,
) {
  const headers = { ...heads };
  const method = methodParm.toUpperCase();
  const token = getToken();
  const lang = getLanguage();
  const country = getCountry();
  const cId = country && country.id ? country.id : '';

  if (token && !headers.Authorization)
    headers.Authorization = `Bearer ${token}`;

  headers.language = lang || 'en';
  let query = '';
  let qs = '';

  const apiData = !formData
    ? omitBy(data, (v) => isUndefined(v) || isNull(v))
    : data;

  if (url === 'webUploadVideo' && formData) {
    apiData.append('type', 'admin');
  }

  if (cId && cId !== 'all') {
    // apiData.country = cId;
    headers.country = cId || '';
  }

  const dataLength = apiData ? Object.keys(apiData).length : 0;
  const body = formData ? apiData : JSON.stringify(apiData);

  if (method === 'GET' && dataLength > 0) {
    Object.keys(apiData).map((key, i) => {
      const sep = i === dataLength - 1 ? '' : '&';
      query += `${encodeURIComponent(key)}=${encodeURIComponent(
        apiData[key],
      )}${sep}`;
    });
    qs = `?${query}`;
  }

  const x = await axios({
    method,
    url: `${siteConfig.apiUrl}api/${url}${qs}`,
    headers,
    data: body,
    timeout,
  });
  if (x && x.data && token && x.data.message === 'Unauthorised' && store) {
    const auth = store ? store.getState().Auth : {};
    store.dispatch(authAction.setLoggedOut(true));
    if (!auth.loggedOut) {
      Notification('error', 'Session expired, Please login again');
      setTimeout(() => {
        store.dispatch(authAction.logout());
      }, 500);
    }
  } else {
    const cmData = store ? store.getState().App.commonData : {};
    if (fetchCommonData || isEmpty(cmData)) {
      store.dispatch(appActions.setCommonData());
    }
  }
  return x?.data;
}

export default apiCall;
