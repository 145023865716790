import { notification } from 'antd';
import { has, isObject } from 'lodash';
import { store } from '@iso/redux/store';

const errMsg = 'Something went wrong. Try later';
const successMsg = 'Success!';

const createNotification = (type, message, description) => {
  const state = store ? store.getState() : {};
  let msg = message || '';
  if (type === 'error' && !msg) msg = errMsg;
  if (type === 'success' && !msg) msg = successMsg;

  const allWords =
    isObject(state) && has(state, 'LanguageSwitcher.allWords')
      ? state.LanguageSwitcher.allWords
      : {};
  const transMsg = isObject(allWords) && allWords[msg] ? allWords[msg] : msg;

  notification[type]({
    message: transMsg,
    description,
    key: transMsg,
  });
};
export default createNotification;
