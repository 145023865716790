import actions from './actions';

const initState = {
  IOSocket: null,
  socketData: {},
  notification: {},
  onProgressData: {},
  onProbabilityProgress: {},
  onReviewProgress: {},
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_IO:
      return {
        ...state,
        IOSocket: action.data,
        socketData: action.socketData || state.socketData,
      };
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.data,
      };
    case actions.SET_ON_PROGRESS:
      return {
        ...state,
        onProgressData: action.data,
      };
    case actions.SET_PROBABILITY_PROGRESS:
      return {
        ...state,
        onProbabilityProgress: action.data,
      };
    case actions.SET_REVIEW_PROGRESS:
      return {
        ...state,
        onReviewProgress: action.data,
      };
    default:
      return state;
  }
}
