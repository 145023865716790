/* eslint-disable no-console */
/* eslint-disable eqeqeq */
import Notification from '@iso/components/Notification';
import siteConfig from '@iso/config/site.config';
import authActions from '@iso/redux/auth/actions';

const socketIOClient = require('socket.io-client');
const sailsIOClient = require('sails.io.js');

let io;
if (socketIOClient.sails) {
  io = socketIOClient;
} else {
  io = sailsIOClient(socketIOClient);
}

io.sails.url = siteConfig.apiUrl;
io.sails.autoConnect = false;

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const actions = {
  NOTIFICATION_INIT: 'NOTIFICATION_INIT',
  SET_IO: 'SET_IO',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_ON_PROGRESS: 'SET_ON_PROGRESS',
  SET_PROBABILITY_PROGRESS: 'SET_PROBABILITY_PROGRESS',
  SET_REVIEW_PROGRESS: 'SET_REVIEW_PROGRESS',

  initilization: () => async (dispatch, getState) => {
    const { IOSocket } = getState().Notification;
    const token = getState().Auth.idToken;
    if (!IOSocket) {
      const socket = io.sails.connect();
      const headers = { ...defaultHeaders };
      if (token) headers.Authorization = `Bearer ${token}`;
      const options = {
        method: 'POST',
        url: '/api/admin/socket-connect',
        headers,
      };

      if (socket) {
        socket.on('connect', () => {
          socket.request(options, (resData) => {
            console.log(
              '🚀 ~ file: actions.js:46 ~ socket.request ~ resData:',
              resData,
            );

            // dispatch(actions.setChatNotification());
          });
        });

        // RECIEVE MESSAGES
        // socket.on('message', (msg) => {
        //   if (isObject(msg) && !isEmpty(msg)) {
        //     dispatch(actions.receiveMsg(msg));
        //   }
        // });

        // RECIEVE TYPING
        socket.on('onProgress', (data) => {
          console.log('🚀 ~ file: actions.js:66 ~ socket.on ~ data:', data);
          dispatch({
            type: actions.SET_ON_PROGRESS,
            data,
          });
        });
        socket.on('onProbDataProgress', (data) => {
          console.log('🚀 ~ file: actions.js:74 ~ onProbDataProgress:', data);
          dispatch({
            type: actions.SET_PROBABILITY_PROGRESS,
            data,
          });
        });

        socket.on('onReviewProgress', (data) => {
          console.log('🚀 ~ file: actions.js:81 ~ onReviewProgress:', data);
          dispatch({
            type: actions.SET_REVIEW_PROGRESS,
            data,
          });
        });
        socket.on('disconnect', () => {
          dispatch({
            type: actions.SET_IO,
            data: null,
            socketData: null,
          });
        });
      }
    }
  },
  receiveNoti: (noti) => (dispatch) => {
    return dispatch({
      type: actions.SET_NOTIFICATION,
      data: noti,
    });
  },
  sendRequest: (url, data = null, callBack = () => {}) => (
    dispatch,
    getState,
  ) => {
    const { IOSocket } = getState().Notification;
    const { idToken } = getState().Auth;
    const headers = { ...defaultHeaders };
    // console.log('REQUEST URL===>');
    // console.log(url);
    // console.log('REQUEST data===>');
    // console.log(data);
    if (idToken && headers) headers.Authorization = `Bearer ${idToken}`;
    const optionsReq = {
      url,
      data,
      method: 'POST',
      headers,
    };
    if (IOSocket) {
      IOSocket.request(optionsReq, (resData) => {
        // console.log('Sails responded with: ', resData);
        if (resData && resData.message === 'Unauthorised') {
          Notification('error', 'Session expired, Please login again');
          setTimeout(() => {
            dispatch(authActions.logout());
          }, 500);
        } else {
          callBack(resData);
        }
      });
    }
  },
  disconnect: () => (_, getState) => {
    const IOSocketVar = getState().Notification.IOSocket;
    if (IOSocketVar) {
      IOSocketVar.disconnect();
    }
  },
};
export default actions;
