const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  ADMINISTRATOR: '/administrator',
  SIGN_UP: '/signup',
  HANDYMAN_WIZARD: '/handyman-verification',
  FORGET_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/resetpassword',
  CREATE_PASSWORD: '/create-password/:tmpToken',
  CHECKOUT: '/checkout/:orderId',
  PAGE_404: '/404',
  PAGE_500: '/500',
};

export default PUBLIC_ROUTE;
