import { addLocaleData } from 'react-intl';
import Enlang from './entries/en-US';
import deLang from './entries/de_DE';

const AppLocale = {
  en: Enlang,
  de: deLang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.de.data);

export default AppLocale;
